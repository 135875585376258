<template>
  <v-content
    id="pages"
    style="background-color: #0c0d0f"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-row
      style="height: 100%; width: 100%"
      :class="$vuetify.breakpoint.smAndDown ? 'ma-0 ' : ''"
    >
      <v-col
        style="overflow: hidden"
        class="pa-0"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <div class="video" style="min-width: 200%; max-height: 100vh">
          <!-- <video
            autoplay
            playsinline
            muted
            loop
            style="max-width: 250% !important;margin-left:-271px; height: 100vh;"
          >
            <source src="@/assets/singup2.mp4" type="video/mp4" />
          </video>-->
          <img
            style="
              max-width: 250% !important;
              margin-left: -271px;
              height: 100vh;
            "
            src="@/assets/singup2.gif"
          />
        </div>
      </v-col>
      <v-col class="pa-0"><router-view /></v-col>
    </v-row>
  </v-content>
</template>

<script>
export default {
  name: "PagesCoreView",

  data: () => ({}),

  computed: {
    src() {
      return this.srcs[this.$route.path];
    },
  },
};
</script>

<style lang="sass">
#pages
  > .v-content__wrap > .v-image
    padding-top: 64px
    padding-bottom: 88px

  .v-responsive__sizer
    display: none

  .v-image--md
    padding: 188px 0

  .v-image--sm
    padding: 96px 0 188px 0

@media (min-width: 1400px)
  video
    min-width: 100%
</style>
